import TagManager from "react-gtm-module";
import { ENABLE_ONETRUST, GTM_TRACKING_ID } from "./config/constants";
import { waitForGlobal } from "./lib/waitForGlobal";

declare global {
  interface Window {
    OnetrustActiveGroups: string;
  }
}

if (GTM_TRACKING_ID !== "") {
  const tagManagerArgs = {
    gtmId: GTM_TRACKING_ID,
  };
  if (ENABLE_ONETRUST) {
    waitForGlobal("OneTrust", () => {
      if (window.OnetrustActiveGroups !== undefined && window.OnetrustActiveGroups.includes("C0001")) {
        TagManager.initialize(tagManagerArgs);
      }
    });
  } else {
    TagManager.initialize(tagManagerArgs);
  }
}

export const waitForGlobal = (key: any, callback: () => void) => {
  const interval = setInterval(function () {
    if (window[key]) {
      clearInterval(interval);
      callback();
    }
  }, 200);
  setTimeout(() => {
    clearInterval(interval);
  }, 10000);
};
